<template>
    <div v-if="page">
        <div class="row mb-3">
            <AppInput label="Title"
                      :value="page.title"
                      @input="updateProp({ title: $event })"
                      :errors="errors"
                      field="title"
                      placeholder="Title"
                      class="col-6"/>
            <AppInput label="Forbidden message"
                      :value="page.forbidden_message"
                      @input="updateProp({ forbidden_message: $event })"
                      :errors="errors"
                      field="forbidden_message"
                      placeholder="Forbidden message"
                      class="col-6"/>
            <AppInput label="Service ID"
                      :value="page.service_id"
                      @input="updateProp({ service_id: $event })"
                      :errors="errors"
                      field="service_id"
                      placeholder="Service ID"
                      class="col-6"/>
        </div>
        <div class="mb-3">
            <VueEditor class="col-12" v-model="pageContent"/>
            <AppError :errors="errors" field="content"/>
        </div>
        <fieldset class="text-end" :disabled="loading">
            <button class="btn btn-primary" @click="save">Save</button>
        </fieldset>
    </div>
</template>

<script>
import AppInput from '@/components/form/AppInput';
import Errors from '@/utils/errors';
import { mapGetters } from 'vuex';
import NotificationService from '@/services/notification.service';
import AppError from '@/components/form/AppError';
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    AppInput,
    AppError,
    VueEditor,
  },
  data() {
    return {
      errors: new Errors(),
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      page: 'pages/formPage',
    }),
    isNew() {
      return this.$route.params.id === 'new';
    },
    pageContent: {
      get() {
        return this.page.content;
      },
      set(val) {
        this.updateProp({ content: val });
      },
    },
  },
  mounted() {
    this.initPage();
  },
  beforeDestroy() {
    this.$store.dispatch('pages/discardNewPage');
  },
  watch: {
    '$route.params.id'() {
      this.initPage();
    },
  },
  methods: {
    initPage() {
      if (this.isNew) {
        this.$store.dispatch('pages/newPage');
      } else {
        this.$store.commit('pages/formPageId', this.$route.params.id);
        this.getPage();
      }
    },
    getPage() {
      this.$store.dispatch('pages/getPage', this.$route.params.id);
    },
    updateProp(props) {
      this.$store.dispatch('pages/pageProps', {
        props,
        pageId: this.page.id,
      });
    },
    async save() {
      this.loading = true;
      this.errors.clear();

      try {
        const res = await this.$store.dispatch(this.isNew ? 'pages/createPage' : 'pages/updatePage', this.page);
        NotificationService.success(res.message);
        await this.$store.dispatch('pages/getPage', res.page_id);
        await this.$store.dispatch('pages/newPage');
      } catch (err) {
        this.errors.set(err.response.data.errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
