<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-4">
            <h5 class="ms-3 mb-0 text-secondary">
                Pages
            </h5>
            <DashboardLink :to="{ name: 'page', params: { id: 'new' } }"
                           class="btn btn-outline-dark ms-3">
                Add page
            </DashboardLink>
        </div>
        <div class="table-responsive scrollbar">
            <table class="table table-hover condensed">
                <thead>
                <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Service ID</th>
                    <th scope="col">Created at</th>
                </tr>
                </thead>
                <tbody class="border-top-0">
                <tr v-for="page in pages" @click="viewPage(page)" class="cursor--pointer">
                    <td>{{ page.title }}</td>
                    <td>{{ page.service_id }}</td>
                    <td>{{ page.created_at | date }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDate } from '@/filters/date.filter';
import DashboardLink from '@/components/navigation/DashboardLink';

export default {
  components: {
    DashboardLink,
  },
  filters: {
    date: formatDate,
  },
  computed: {
    ...mapGetters({
      pages: 'pages/all',
    }),
  },
  watch: {
    '$route.params.section'() {
      this.getPages();
    },
  },
  mounted() {
    this.getPages();
  },
  methods: {
    getPages() {
      this.$store.dispatch('pages/getPages');
    },
    viewPage(page) {
      this.$router.push({ name: 'page', params: { id: page.id } });
    },
  },
};
</script>
