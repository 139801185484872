<template>
    <div>
        <div class="row" :class="formPageId ? 'justify-content-between' : 'justify-content-center'">
            <PagesList class="col-xxl-8 col-xl-7 pe-5" :class="formPageId ? 'col-xl-7 col-lg-7' : 'col-12'"/>
            <transition name="slideLeftFade">
                <div class="col-lg-4 sidebar sidebar--right scrollbar" v-if="formPageId">
                    <div class="d-flex justify-content-between align-items-center py-3">
                        <h5 class="mb-0">{{ isNew ? 'New page' : `Page` }}</h5>
                        <router-link :to="{ name: 'pages' }" class="btn">
                        <span class="material-symbols-rounded align-middle">
                            close
                        </span>
                        </router-link>
                    </div>
                    <PageForm class="mb-4"/>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import PagesList from '@/components/pages/PagesList';
import PageForm from '@/components/pages/PageForm';

export default {
  components: {
    PageForm,
    PagesList,
  },
  computed: {
    formPageId() {
      return this.$route.params.id;
    },
    isNew() {
      return this.formPageId === 'new';
    },
  },
};
</script>
